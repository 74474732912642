import React from 'react'
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "./misc/Headings.js";
import { Container as ContainerBase } from "./misc/Layouts";


const Container = tw(
    ContainerBase
  )`min-h-screen text-white font-medium flex justify-center -m-8`;
  const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
  const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
  const LogoLink = tw.a``;
  const LogoImage = tw.img`h-12 mx-auto`;
  const MainContent = tw.div`mt-6 flex flex-col items-center`;
  const Heading = tw.h1`text-xl xl:text-2xl font-extrabold`;
  const FormContainer = tw.div`w-full flex-1 mt-3`;
  
  const SocialButtonsContainer = tw.div`flex flex-col items-center`;
  const SocialButton = styled.a`
    ${tw`flex items-center justify-center w-full max-w-xs py-3 mt-5 text-sm font-semibold text-gray-900 transition-all duration-300 border rounded-lg hocus:bg-gray-200 hocus:border-gray-400 focus:outline-none focus:shadow-outline first:mt-0`}
    .iconContainer {
      ${tw`p-2 bg-white rounded-full`}
    }
    .icon {
      ${tw`w-8`}
    }
    .text {
      ${tw`ml-4`}
    }
  `;

  const Subheading = tw(SubheadingBase)`text-center md:text-left`;
//const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
  
  const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
  const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;
  
  const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
  const LinksContainer = tw.div`mt-5`;
  const Input = tw.input`mt-6 first:mt-0 bg-transparent border-t-0 border-t-0 border-r-0 border-l-0 border-b-2 py-3 focus:outline-none focus:border-none  focus:border-transparent focus:ring-0 font-medium transition duration-300 hocus:border-primary-500`;
  const SubmitButton = styled.button`
    ${tw`flex items-center justify-center w-full py-4 mt-5 font-semibold tracking-wide text-gray-100 transition-all duration-300 ease-in-out bg-orange-500 rounded-lg hover:bg-orange-100 focus:shadow-outline focus:outline-none`}
    .icon {
      ${tw`w-6 h-6 -ml-2`}
    }
    .text {
      ${tw`ml-3`}
    }
  `;
  const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
  const IllustrationImage = styled.div`
    ${(props) => `background-image: url("${props.imageSrc}");`}
    ${tw`w-full max-w-lg m-12 bg-center bg-no-repeat bg-contain xl:m-16`}
  `;
  
const Activation = ({
    logoLinkUrl = "#",
    illustrationImageSrc = 'https://res.cloudinary.com/deuo4szkv/image/upload/v1708896784/confimedorder_ulw23y.svg',
    headingText = "Account Activation",
    subheading = "Contact Us",
    heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr /> with us.</>,
    description = "Have a question, comment, or suggestion? We'd love to hear from you! Feel free to reach out to us using the contact information below, and we'll get back to you as soon as possible.",
    socialButtons = [
      {
        iconImageSrc: 'https://res.cloudinary.com/deuo4szkv/image/upload/v1710281030/icons/gmail-new-icon5198_wyjsop.jpg',
        text: "Open gmail",
        url: "gmail.com",
      },
      {
        iconImageSrc: 'https://res.cloudinary.com/deuo4szkv/image/upload/v1710281032/icons/outlook_icon_closeup_3x_4x_lhmzke.png',
        text: "Open Outlook",
        url: "outlook.com",
      },
    ],
    submitButtonText = "Sign Up",
 
    tosUrl = "/termaandconditions",
    privacyPolicyUrl = "/privacy",
    signInUrl = "/login",
  }) => {
  return (
    <div>
      <Container>
        <Content>
          <MainContainer>
            <MainContent>
              <FormContainer>
              {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{headingText}</Heading>
            {description && <Description>{description}</Description>}
                
                <SocialButtonsContainer>
                  {socialButtons.map((socialButton, index) => (
                    <SocialButton key={index} onClick={(e) => {
                        e.preventDefault();

                         
                    }}>
                      <span className="iconContainer">
                        <img
                          src={socialButton.iconImageSrc}
                          className="icon"
                          alt=""
                        />
                      </span>
                      <span className="text">{socialButton.text}</span>
                    </SocialButton>
                  ))}
                </SocialButtonsContainer>
        
              </FormContainer>
           
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </div>
  )
}

export default Activation
