import React from "react";
import PickMeals from "../Assets/pick-meals-image.png";
import ChooseMeals from "../Assets/choose-image.png";
import DeliveryMeals from "../Assets/delivery-image.png";

const Work = () => {
  const workInfoData = [
    {
      image: PickMeals,
      title: "Pre-packed baskets ",
      text: "Our pre-pack basket is a variety box combo of 5 or more different sizes of already prepared and ready to eat vegetable packs that makes it easier and convenient for our clients to prepare their meals just in time. Packs can also be purchased as additionals to combos. "
    },
    {
      image: ChooseMeals,
      title: "Seasonal box",
      text: "Our seasonal box is a variety combo box of fruits and vegetables put together by you as our client for your desired recipe or fruit needs, choosing from a selection of seasonal vegetables and fruits subject to their availability on the market in the week of delivery. We also offer readily Selected combos put together by us at the store based on our best selling or popular items. A client can always request to replace a particular item on the “readily selected combos” subject to cost changes also been effected.",
    },
    {
      image: DeliveryMeals,
      title: "Gesture Flowers Botique",
      text: "No occasion of yours is too small for us. We value all moments of your life journey that’s why we are here to help you express yourself in all of them with a selected range of flowers to choose from in our boutique packaged with love, care and elegance every occasion.",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Services</p>
        <h1 className="primary-heading">What we do</h1>
        <p className="primary-text">
          Order now and have fresh vegetables at your doorstep ASAP.
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
