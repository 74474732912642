import tw from 'twin.macro';
import { useState, useMemo } from 'react';
import emailjs from '@emailjs/browser';
import CartItem from './CartItem';
import { useDispatch, useSelector } from 'react-redux';
import { createOrder } from '../redux/orderSlice';
import styled from "styled-components";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SectionHeading, Subheading as SubheadingBase } from "./misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "./misc/Buttons.js";
import EmailIllustrationSrc from "../Assets/images/email-illustration.svg";
import { Routes, Route, useNavigate } from 'react-router-dom';
import { clearCart } from '../redux/cartSlice';
import OrderConfirmation from './OrderConfirmation';
import CheckoutForm from './checkout/Checkout';
 
 
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
 

const TextColumn = styled(Column)(props => [
  tw`mt-16 md:w-7/12 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);



const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-full bg-center bg-no-repeat bg-contain rounded`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

 
 

const OrderForm = ({
 
  textOnLeft = true,
}) => {
  const {
    submitted
  } = useSelector((store) => store.order);
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"

      />
     {!submitted ?  <Container> 
        <TwoColumn>
          <ImageColumn>
            <Image imageSrc={"https://res.cloudinary.com/deuo4szkv/image/upload/v1708727892/checkout_lwlqyu.png"} />
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
            <CheckoutForm />
            </TextContent>
          </TextColumn>
        </TwoColumn> 
      </Container> : <>
    <OrderConfirmation />
    </>}
    </>
  );
};

export default OrderForm;