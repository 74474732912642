import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { useDispatch, useSelector } from 'react-redux';
import { addItem } from '../../redux/cartSlice';
import { Container, ContentWithPaddingXl } from "../misc/Layouts.js";
import { SectionHeading } from "../misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons.js";
import { ReactComponent as StarIcon } from "../../Assets/images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../Assets/images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../Assets/images/svg-decorator-blob-7.svg";
import { ToastContainer, toast } from 'react-toastify';
const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`w-1/2 px-6 py-3 mt-2 text-sm font-medium text-center text-gray-600 transition duration-300 rounded-sm cursor-pointer sm:mt-0 sm:mr-2 last:mr-0 sm:text-base sm:w-auto`}
  &:hover {
    ${tw`text-gray-700 bg-gray-300`}
  }
  ${props => props.active && tw`bg-orange-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`relative h-56 bg-center bg-cover rounded-t xl:h-64`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`flex items-end mr-1 text-sm font-bold`}
  svg {
    ${tw`w-4 h-4 mr-1 text-orange-400 fill-current`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex items-center justify-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`absolute top-0 right-0 w-64 h-64 text-pink-400 transform -translate-y-12 pointer-events-none -z-20 opacity-15 translate-x-2/3`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`absolute bottom-0 left-0 transform pointer-events-none -z-20 h-80 w-80 opacity-15 -translate-x-2/3 text-primary-500`}
`;


// Function to generate a random ID
function generateRandomId() {
  return Math.random().toString(36).slice(2, 9); // Generate a random string of 9 characters
}
const notify = () => {

  toast.success('Item added to cart!', {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    progress: undefined,
    draggable: true,
    theme: "light",
  })
};
export default ({
  heading = "Checkout the produce",
  tabs = {
    Vegetables: [
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708463840/tomato_sxpqfu.jpg",
        title: "Tomatos",
        content: "Juicy and flavorful tomatoes, perfect for salads, sauces, and sandwiches.",
        price: "25.99 Rands/kg",
        formattedPrice: "25.99",
        rating: "4.8",
        reviews: "127",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708463841/carrot_x7nkby.jpg",
        title: "Carrots",
        content: "Crunchy and sweet carrots, perfect for snacking, salads, and soups.",
        price: "19.99 Rands/kg",
        formattedPrice: "19.99 ",
        rating: "4.9",
        reviews: "95",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708463840/spinach_mgrdnh.jpg",
        title: "Spinach",
        content: "Nutrient-packed spinach, perfect for salads, smoothies, and cooked dishes.",
        price: "15.99 Rands/bunch",
        formattedPrice: "15.99",
        rating: "4.7",
        reviews: "84",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708463841/brocolli_deexri.jpg",
        title: "Broccoli",
        content: "Nutrient-rich broccoli, perfect for roasting, stir-frying, and steaming.",
        price: "18.99 Rands/kg",
        formattedPrice: "18.99",
        rating: "4.6",
        reviews: "76",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708463840/cucumber_va4elb.jpg",
        title: "Cucumber",
        content: "Refreshing and hydrating cucumbers, perfect for salads and snacking.",
        price: "12.99 Rands/kg",
        formattedPrice: "12.99 ",
        rating: "4.5",
        reviews: "68",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708463841/pepper_hfsmay.jpg",
        title: "Bell Peppers",
        content: "Colorful bell peppers, perfect for stuffing, grilling, and adding to salads.",
        price: "29.99 Rands/kg",
        formattedPrice: "29.99",
        rating: "4.7",
        reviews: "91",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708463840/lettuce_hjor5f.jpg",
        title: "Lettuce",
        content: "Fresh and crisp lettuce, perfect for salads, wraps, and sandwiches.",
        price: "14.99 Rands/head",
        formattedPrice: "14.99 ",
        rating: "4.6",
        reviews: "79",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708463841/onion_kks6em.jpg",
        title: "Onion",
        content: "Versatile onions, perfect for cooking, grilling, and adding flavor to dishes.",
        price: "8.99 Rands/kg",
        formattedPrice: "8.99 ",
        rating: "4.4",
        reviews: "57",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708463840/zuchini_o6ecmb.jpg",
        title: "Zucchini",
        content: "Versatile zucchinis, perfect for grilling, roasting, and adding to pasta dishes.",
        price: "22.99 Rands/kg",
        formattedPrice: "22.99",
        rating: "4.6",
        reviews: "72",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708463841/potato_wwpyyk.jpg",
        title: "Potatos",
        content: "Versatile potatoes, perfect for baking, mashing, and frying.",
        price: "6.99 Rands/kg",
        formattedPrice: "6.99 ",
        rating: "4.5",
        reviews: "63",
        url: "#"
      },
    ],
    Fruits: [
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708548510/apples_roaqcq.jpg",
        title: "Apples",
        content: "Crisp and refreshing apples, perfect for snacking and baking.",
        price: "29.99 Rands/kg",
        formattedPrice: "29.99",
        rating: "4.7",
        reviews: "103",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708548509/bananas_xp4lfe.jpg",
        title: "Bananas",
        content: "Sweet and nutritious bananas, perfect for snacking and smoothies.",
        price: "19.99 Rands/kg",
        formattedPrice: "19.99",
        rating: "4.6",
        reviews: "88",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708548508/cherries_lpuwsm.jpg",
        title: "Cherries",
        content: "Juicy and sweet cherries, perfect for snacking and desserts.",
        price: "49.99 Rands/kg",
        formattedPrice: "49.99",
        rating: "4.9",
        reviews: "115",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708548509/blueberries_xij72a.jpg",
        title: "Blueberries",
        content: "Sweet and antioxidant-rich blueberries, perfect for snacking and baking.",
        price: "69.99 Rands/kg",
        formattedPrice: "69.99",
        rating: "4.8",
        reviews: "92",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708548508/oranges_avui6g.jpg",
        title: "Oranges",
        content: "Juicy and vitamin-rich oranges, perfect for juicing and snacking.",
        price: "24.99 Rands/kg",
        formattedPrice: "24.99",
        rating: "4.7",
        reviews: "107",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708548508/peaches_r1wpor.jpg",
        title: "Peaches",
        content: "Sweet and juicy peaches, perfect for snacking and desserts.",
        price: "39.99 Rands/kg",
        formattedPrice: "39.99",
        rating: "4.8",
        reviews: "99",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708548508/pears_sf6pyv.jpg",
        title: "Pears",
        content: "Sweet and juicy pears, perfect for snacking and baking.",
        price: "34.99 Rands/kg",
        formattedPrice: "34.99",
        rating: "4.7",
        reviews: "103",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708548508/strawberries_qruer4.jpg",
        title: "Strawberries",
        content: "Sweet and juicy strawberries, perfect for snacking and desserts.",
        price: "59.99 Rands/kg",
        formattedPrice: "59.99",
        rating: "4.9",
        reviews: "120",
        url: "#"
      },
      {
        id: generateRandomId(),
        imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708548508/grapes_pwggnp.jpg",
        title: "Grapes",
        content: "Sweet and delicious grapes, perfect for snacking and making wine.",
        price: "44.99 Rands/kg",
        formattedPrice: "44.99",
        rating: "4.8",
        reviews: "97",
        url: "#"
      },
      // Add more fruit objects as needed
    ],
    Combos: getCombos(),
    Specials: []
  }
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);
  const dispatch = useDispatch();
  return (
    <Container>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"

      />
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >

            {Object.keys(tabs[tabKey]).length > 0 ? tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card className="group" onClick={() => {
                  dispatch(addItem(card));
                  notify();
                }} initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer imageSrc={card.imageSrc}>
                    <CardRatingContainer>
                      <CardRating>
                        <StarIcon />
                        {card.rating}
                      </CardRating>
                      <CardReview>({card.reviews})</CardReview>
                    </CardRatingContainer>
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton >Add To Cart</CardButton>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.content}</CardContent>
                    <CardPrice>{card.price}</CardPrice>
                  </CardText>
                </Card>
              </CardContainer>
            )
            ) : <CardContainer >
              <span> No {activeTab} at the moment</span>


            </CardContainer>
            }
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

/* This function is only there for demo purposes. It populates placeholder cards */
const getCombos = () => {
  const cards = [
    {
      id: generateRandomId(),
      imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708727777/veg_k4cpl5.jpg",
      title: "Summer veg combo",
      content: "Peppers, sweetcorn, butternut, celery, sweet potato.",
      price: "R249.99",
      formattedPrice: "249.99",
      rating: "4.7",
      reviews: "107",
      url: "#"
    },
    {
      id: generateRandomId(),
      imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708727777/veg2_mphq7c.jpg",
      title: "Winter veg combo",
      content: "Peppers, carrots, potatos, beetroot, zuchini.",
      price: "R249.99",
      formattedPrice: "249.99",
      rating: "4.8",
      reviews: "99",
      url: "#"
    },
    {
      id: generateRandomId(),
      imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708813973/applecombo_oycnyh.jpg",
      title: "Winter fruit combo",
      content: "Sweet and juicy pears,apples and oranges.",
      price: "R134.99",
      formattedPrice: "134.99",
      rating: "4.7",
      reviews: "103",
      url: "#"
    },
    {
      id: generateRandomId(),
      imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708813983/tropical-mix_v37jde.jpg",
      title: "Summer fruit combo",
      content: "Sweet and juicy strawberries, Pineapple, Mango, Kiwi, Grapes.",
      price: "R159.99",
      formattedPrice: "159.99",
      rating: "4.9",
      reviews: "120",
      url: "#"
    },
  ];

  // Shuffle array
  return cards.sort(() => Math.random() - 0.5);
};
