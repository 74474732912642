import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { SectionHeading, Subheading as SubheadingBase } from "./misc/Headings.js";
import { SectionDescription } from "./misc/Typography.js";
import { Container, ContentWithPaddingXl } from "./misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../Assets/images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../Assets/images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full text-center`;
const Description = tw(SectionDescription)`w-full text-center`;
const Column = tw.div`flex flex-col items-center`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;


const HeaderContent = tw.div``;
const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`h-full bg-center bg-no-repeat bg-contain rounded`,
  ]);
const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`absolute top-0 right-0 w-56 h-56 text-teal-400 transform -translate-y-12 pointer-events-none -z-20 opacity-15 translate-x-2/3`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`absolute bottom-0 left-0 w-64 h-64 transform pointer-events-none -z-20 opacity-15 -translate-x-2/3 text-primary-500`}
`;



export default ({
    subheading = "Order Confirmation",
    heading = "You order has been confirmed",
    description = "You will be notified via email and/or sms once your order is ready for delivery.",

}) => {




    return (
        <Container>
            <ContentWithPaddingXl>
                <Column>
                    <HeaderContent>
                        <Subheading>{subheading}</Subheading>
                        <Heading>{heading}</Heading>
                      <Description>{description}</Description>
                    </HeaderContent>
                    <FAQSContainer>
                        <ImageColumn> 
                            <Image imageSrc={"https://res.cloudinary.com/deuo4szkv/image/upload/v1708896813/orderconfimed_mncuoa.svg"} />
                        </ImageColumn>
                    </FAQSContainer>
                </Column>
            </ContentWithPaddingXl>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
        </Container>
    );
};
