import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { openModal } from './modalSlice';

const url = '';

const initialState = {
  cartItems: [],
  amount: 4,
  total: 0,
  isLoading: true,
};

export const getCartItems = createAsyncThunk(
  'cart/getCartItems',
  async (name, thunkAPI) => {
    try {
      // console.log(name);
      // console.log(thunkAPI);
      // console.log(thunkAPI.getState());
      // thunkAPI.dispatch(openModal());
      const resp = await axios(url);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

const createExtraReducers = () => {
  return (builder) => {
    getItems();

    function getItems() {
        var { pending, fulfilled, rejected } = getCartItems;
        builder
            .addCase(pending, (state) => {
              state.isLoading = true;
            })
            .addCase(fulfilled, (state, action) => {
              state.isLoading = false;
              state.cartItems = action.payload;
            })
            .addCase(rejected, (state, action) => {
              console.log(action);
              state.isLoading = false;
            });
    }
};
}
const extraReducers = createExtraReducers();
const uuid = require("uuid")

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    clearCart: (state) => {
      state.cartItems = [];
      state.amount = 0;
      state.total = 0;
    },
    addItem: (state, action) => {
      const { id ,title, formattedPrice, imageSrc } = action.payload;
      console.log(action.payload)
      const existingItemIndex = state.cartItems.findIndex(item => item.id ===  id);
      console.log(existingItemIndex)
      if (existingItemIndex !== -1) {
        // If similar item exists, increase its quantity
        console.log( state.cartItems[existingItemIndex].amount)
        state.cartItems[existingItemIndex].amount += 1;
      } else {
        // If not, add the new item to the cart
        const newItem = {
          id,
          title,
          price: formattedPrice,
          img: imageSrc,
          amount: 1
        };

        state.cartItems = [...state.cartItems, newItem];
      }
      console.log(state.cartItems);
      state.amount++;
    },
    removeItem: (state, action) => {
      const itemId = action.payload;
      state.cartItems = state.cartItems.filter((item) => item.id !== itemId);
      state.amount--;
    },
    increase: (state, { payload }) => {
      const cartItem = state.cartItems.find((item) => item.id === payload.id);
      cartItem.amount = cartItem.amount + 1;
    },
    decrease: (state, { payload }) => {
      const cartItem = state.cartItems.find((item) => item.id === payload.id);
      cartItem.amount = cartItem.amount - 1;
    },
    calculateTotals: (state) => {
      let amount = 0;
      let total = 0;
      state.cartItems.forEach((item) => {
        amount += item.amount;
        total += item.amount * item.price;
      });
      state.amount = amount;
      state.total = total;
    },
  },
});

// console.log(cartSlice);
export const { clearCart, addItem,removeItem, increase, decrease, calculateTotals } =
  cartSlice.actions;

export default cartSlice.reducer;
