import Footer from 'Components/Footer'
import Navbar from 'Components/Navbar'
import OrderForm from 'Components/OrderForm'
import AnimationRevealPage from 'helpers/AnimationRevealPage'
import React from 'react'

const Checkout = () => {
  return (
    <AnimationRevealPage>
      <Navbar />
      <OrderForm />
      <Footer />
    </AnimationRevealPage>
  )
}

export default Checkout
