import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
 
import { PURGE } from "redux-persist";

const initialState = {
  user: {},
  isLoading: false,
};

export const SignUpUser = createAsyncThunk(
  'cart/getCartItems',
  async (name, thunkAPI) => {
    try {
      // console.log(name);
      // console.log(thunkAPI);
      // console.log(thunkAPI.getState());
      // thunkAPI.dispatch(openModal());
    //   const resp = await axios(url);

    //   return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

const createExtraReducers = () => {
  return (builder) => {
    SignUp();
 
    function SignUp() {
        var { pending, fulfilled, rejected } = SignUpUser;
        builder
            .addCase(pending, (state) => {
              state.isLoading = true;
             })
            .addCase(fulfilled, (state, action) => {
              state.isLoading = false;
              state.user = action.payload;
            })
            .addCase(rejected, (state, action) => {
              console.log(action);
              state.isLoading = false;
            });
    }
 
};
}
const extraReducers = createExtraReducers();
const uuid = require("uuid")

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
        state.user = {};
    },
    addUser: (state, action) => {
        state.user = {
         email : action.payload.email,
          displayName: action.payload.displayName
        }
    },
  },
});

// console.log(cartSlice);
export const { logout, addUser} =
userSlice.actions;

export default userSlice.reducer;
