import { configureStore, combineReducers } from '@reduxjs/toolkit'
import cartReducer from './cartSlice';
import modalReducer from './modalSlice';
import orderReducer from './orderSlice';
import userReducer from './userSlice';
import {
  persistStore, persistReducer, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';



const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  // Optionally, you can whitelist specific reducers to be persisted
  // whitelist: ['cart']
};

const rootReducer = combineReducers({ cart: cartReducer, modal: modalReducer, order: orderReducer, user: userReducer });


const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

