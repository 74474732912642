import Footer from 'Components/Footer'
import Navbar from 'Components/Navbar'
import TabCardGrid from 'Components/cards/TabCardGrid'
import AnimationRevealPage from 'helpers/AnimationRevealPage'
import React from 'react'

const Store = () => {
  return (
    <AnimationRevealPage>
        <Navbar />
        <TabCardGrid />
        <Footer />
    </AnimationRevealPage>
  )
}

export default Store
