import Footer from 'Components/Footer'
import Navbar from 'Components/Navbar'
import Stepper from 'Components/Stepper'
import AnimationRevealPage from 'helpers/AnimationRevealPage'
import React , { useState} from 'react'

const Orders = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const NUMBER_OF_STEPS = 5
  const steps = [
    
    { title: "Order Confirmed", content: "Your order has been confirmed." },
    { title: "Order Preparation", content: "We are preparing your order." },
    { title: "Order Delivery", content: "Your order is out for delivery." }
  ];
  return (
    <AnimationRevealPage>
      <Navbar />
      <div className="flex flex-col items-center justify-center h-screen gap-10 bg-gray-900">
      <Stepper currentStep={currentStep} numberOfSteps={NUMBER_OF_STEPS}/>
    </div>
      <Footer />
    </AnimationRevealPage>
  )
}

export default Orders
