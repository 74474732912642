import React, { useState } from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "../Components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import illustration from "../Assets/images/login-illustration.svg";
import logo from "../Assets/images/logo.svg";
 import twitterIconImageSrc from "../Assets/images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import Navbar from "Components/Navbar.js";
import Footer from "Components/Footer.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { auth } from "../firebase/config.js";
import { addUser } from "../redux/userSlice.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import { signInWithPopup, TwitterAuthProvider } from "firebase/auth";
import FullPageLoader from "Components/FullPageLoader.js";

const Container = tw(
  ContainerBase
)`min-h-screen text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-6 flex flex-col items-center`;
const Heading = tw.h1`text-xl xl:text-2xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-3`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`flex items-center justify-center w-full max-w-xs py-3 mt-5 text-sm font-semibold text-gray-900 transition-all duration-300 bg-gray-100 border rounded-lg cursor-pointer hocus:bg-gray-200 hocus:border-gray-400 focus:outline-none focus:shadow-outline first:mt-0`}
  .iconContainer {
    ${tw`p-2 bg-white rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const LinksContainer = tw.div`mt-5`;
const LinksText = tw.div`text-sm text-orange-600 text-left`;
const LinksTag = tw.div`cursor-pointer`;
const Input = tw.input`mt-6 first:mt-0 bg-transparent border-t-0 border-t-0 border-r-0 border-l-0 border-b-2 py-3 focus:outline-none focus:border-none  focus:border-transparent focus:ring-0 font-medium transition duration-300 hocus:border-primary-500`;
const SubmitButton = styled.button`
  ${tw`flex items-center justify-center w-full py-4 mt-5 font-semibold tracking-wide text-gray-100 transition-all duration-300 ease-in-out bg-orange-500 rounded-lg cursor-pointer focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`w-full max-w-sm m-12 bg-center bg-no-repeat bg-contain xl:m-16`}
`;

export default ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Sign In To Maveg",
  socialButtons = [
    {
      iconImageSrc: 'https://res.cloudinary.com/deuo4szkv/image/upload/v1710359296/icons/facebook-icon_pzrm9w.svg',
      text: "Sign In With Facebook",
      name: "facebook",
    },
    {
      iconImageSrc: twitterIconImageSrc,
      text: "Sign In With Twitter",
      name: "twitter",
    },
  ],
  submitButtonText = "Sign In",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "/forgotpassword",
  signupUrl = "/signup",
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const provider = new TwitterAuthProvider();
  const location = useLocation();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((store) => store.user);
  const [userData, setUserData] = useState({});

  const twitterSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
        // You can use these server side with your app's credentials to access the Twitter API.
        const credential = TwitterAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const secret = credential.secret;

        // The signed-in user info.
        const user = result.user;
        setUserData({});
        dispatch(addUser(user));
        // navigate to previous page
        // Check if user was redirected from the cart
        if (location.state && location.state.from === "cart") {
          // Redirect user back to the cart
          navigate("/checkout");
        } else {
          // Redirect user to the homepage
          navigate("/");
        }
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = TwitterAuthProvider.credentialFromError(error);
        console.log(errorMessage);
        // ...
      });
  };

  const faceBookSignIn = () => {

  }
  const handleSubmit = (e) => {
  
    e.preventDefault();
    const newErrors = {};
   
    if (!userData.email) {
      console.log('signing in')
      newErrors.email = "Email is required";
    }

   

    if (!userData.password) {
      newErrors.password = "Password is required";
    }
    // You can add more validation rules for other fields here
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setIsLoading(true);
    signInWithEmailAndPassword(auth, userData.email, userData.password)
      .then((userCredential) => {
        // Signed up
        const user = userCredential.user;
        setIsLoading(false);
        console.log(user);
        if(!user.emailVerified) {
          navigate('/activation');
          return;
        }
        setUserData({});
        dispatch(addUser(user));
        // navigate to previous page
        // Check if user was redirected from the cart
        if (location.state && location.state.from === "cart") {
          // Redirect user back to the cart
          navigate("/checkout");
        } else {
          // Redirect user to the homepage
          navigate("/");
        }
      })
      .catch((error) => {
        newErrors.errorCode = error.code;
        newErrors.errorMessage = error.message;
        //setErrors(newErrors);
        setIsLoading(false);
        notify();
        console.log(newErrors);
        // ..
      });
  };
 
  const notify = () => {
    toast.error('Invalid credentials!', {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  };
  const handleChange = ({ target: { name, value } }) => {
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  if(isLoading) {
    return <FullPageLoader></FullPageLoader>

  }

  return (
    <AnimationRevealPage>
       <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"

      />
      <Navbar />
      <Container>
        <Content>
          <MainContainer>
            <MainContent>
              {/* <Heading>{headingText}</Heading> */}
              <FormContainer>
                <SocialButtonsContainer>
                  {socialButtons.map((socialButton, index) => (
                    <SocialButton key={index} onClick={(e) => {
                        e.preventDefault();

                        socialButton.name == "twitter" ? twitterSignIn() : faceBookSignIn();
                    }}>
                      <span className="iconContainer">
                        <img
                          src={socialButton.iconImageSrc}
                          className="icon"
                          alt=""
                        />
                      </span>
                      <span className="text">{socialButton.text}</span>
                    </SocialButton>
                  ))}
                </SocialButtonsContainer>
                <DividerTextContainer>
                  <DividerText>Or Sign in with your e-mail</DividerText>
                </DividerTextContainer>
                <Form onSubmit={handleSubmit} >
                  <Input type="email" placeholder="Email"  onChange={handleChange}
                    name="email" value={userData.email} />
                  <Input type="password" placeholder="Password" name="password"
                    value={userData.password}  onChange={handleChange} />
                  <SubmitButton type="submit">
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                </Form>
                <LinksContainer>
                  <LinksText>
                    <LinksTag
                     onClick={(e) => {
                        e.preventDefault();
                        navigate('/forgotpassword')
                     }}
                      tw="border-b border-gray-500 border-dotted cursor-pointer"
                    >
                      Forgot Password ?
                    </LinksTag>
                  </LinksText>
                  <LinksText>
                   
                    <LinksTag
                           onClick={(e) => {
                            e.preventDefault();
                            navigate('/signup')
                         }}
                      
                    >
                    Dont have an account?{" "}   Sign Up
                    </LinksTag>
                  </LinksText>
                </LinksContainer>
                 <ul>
                 {  
                 Object.keys(errors).length > 0 &&  
                  <ul>
                    <li> {errors.errorMessage}</li>
                  </ul>   
               
                  }
                  </ul> 
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
