import React from "react";
import ProfilePic from "../Assets/john-doe-image.png";
import { AiFillStar } from "react-icons/ai";

const Testimonial = () => {
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Phepo scheme</p>
        <h1 className="primary-heading">Food security for all</h1>
        <p className="primary-text">
        Phepho scheme is a poverty aliviention project more than a rendered service by our fresh produce. The project allows Co-oporates, NGO's, NPO'"s and individuals to purchase and sponsor seasonal box tokens for boxes to be delivered monthly to child headed house holds that our company will recruit to be beneficiaries.
        </p>
        <p className="primary-text">
        Depending on the monthly raised budget, the company will put together and equal value box to be distributed equaly amongst the child headed household on our data base. The boxes will be delivered every last week of the month. All sponsors will receive a monthly report of the total amount raised, number of famalies that benefited and the value of the seasonal box as well as a list and quantity of items in the seasonal box.
        </p>
      </div>
 
    </div>
  );
};

export default Testimonial;
