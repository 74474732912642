import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "../Components/headers/light.js";
import Footer from "../Components/Footer.js"
import ContactUsForm from "../Components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "../Components/cards/ThreeColContactDetails.js";
import Navbar from "Components/Navbar.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Navbar />
      <ContactUsForm />
      <ContactDetails
        cards={[
          {
            title: "Auckland park",
            description: (
              <>
                <Address>
                  <AddressLine>40 Gates Court</AddressLine>
                  <AddressLine>Auckland Park, Johannesburg 13760</AddressLine>
                </Address>
                <Email>contact@maveg.co.za</Email>
                <Phone>+27 (011) 991-6988</Phone>
              </>
            )
          },
           
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
