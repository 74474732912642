import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { openModal } from './modalSlice';



const initialState = {

    id: '',
    orderCreated: false,
    customer_name: '',
    customer_lname: '',
    customer_phone: '',
    delivery_type: 'Pickup',
    address_line_1: '',
    address_line_2: '',
    city: '',
    province: '',
    postal_code: '',
    payment_method: 'Cash',
    bank_account_name: 'FNB Business Bank Account',
    bank_account_number: '089030919291',
    bank_name: 'FNB',
    branch_code: '19089',
    cartItems: [],
    total_amount: 0,
    submitted: true

};


const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        cancelOrder: (state) => {
            state.cartItems = [];
        },
 
        updateFormData: (state, { payload }) => {
            console.log(payload);
            // state.customer_fname = payload.customer_fname;
            // state.customer_lname = payload.customer_lname;
            // state.customer_phone = payload.customer_lname;
            // state.delivery_type = payload.customer_lname;
            // state.address_line_1 = payload.address_line_1;
            // state.address_line_2 = payload.address_line_2;
            // state.city = payload.city;
            // state.province = payload.province;
            // state.postal_code = payload.postal_code;
            Object.keys(payload).forEach(key => {
                if (state.hasOwnProperty(key)) {
                    state[key] = payload[key];
                }
            });
        },
        setDeliveryType: (state, { payload }) => {
            state.delivery_type = payload.delivery_type;
        },
        createOrder: (state, action) => {

        },
    },
});


export const { updateFormData, createOrder } =
    orderSlice.actions;

export default orderSlice.reducer;
