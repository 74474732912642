import React, { Children } from 'react'
import {Navigate , useLocation} from 'react-router-dom'
import { useSelector } from 'react-redux';
import { auth } from '../firebase/config.js';
const Protected = ({children}) => {
  const location = useLocation();
const { user } = useSelector((store) => store.user);
const currentUser = auth.currentUser;
console.log(currentUser , user);
  return user && currentUser ? children : <Navigate to="/login"  />;
}

export default Protected
