import Activation from "Components/Activation";
import Footer from "Components/Footer";
import Navbar from "Components/Navbar";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import React from "react";

const AccountActivation = () => {
  return (
    <AnimationRevealPage>
      <Navbar />
      <Activation />
      <Footer />
    </AnimationRevealPage>
  );
};

export default AccountActivation;
