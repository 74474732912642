import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForms';
import Review from './Review';
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons.js";
import tw from "twin.macro";
import styled from "styled-components";
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData } from '../../redux/orderSlice';
import emailjs from '@emailjs/browser';
import FullPageLoader from "..//FullPageLoader.js";

import { ToastContainer, toast } from 'react-toastify';
import { clearCart } from '../../redux/cartSlice';
import OrderConfirmation from 'Components/OrderConfirmation';
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`inline-block mx-auto mt-8 text-sm md:mt-8 md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const steps = ['Shipping address', 'Payment details', 'Review your order'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <AddressForm />;
    case 1:
      return <PaymentForm />;
    case 2:
      return <Review />;
    default:
      throw new Error('Unknown step');
  }
}

export default function CheckoutForm() {
  const [activeStep, setActiveStep] = React.useState(0);
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);

  const {
    customer_name,
    customer_lname,
    customer_phone,
    payment_method,
    delivery_type,
    address_line_1,
    address_line_2,
    city,
    province,
    postal_code
  } = useSelector((store) => store.order);
  const [errors, setErrors] = React.useState({});
  const { cartItems, total, amount } = useSelector((store) => store.cart);
  const [isLoading, setIsLoading] =  React.useState(false);
  const notify = () => {
    toast.success('Order sent successfuly!', {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  };
  const sendMail = () => {
     
    setIsLoading(true)
    const newErrors = {};
    if (!customer_name) {
      newErrors.customer_name = 'Name is required';
    }
    if (!customer_phone) {
      newErrors.customer_phone = 'Email is required';
    }
    // You can add more validation rules for other fields here
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }


    emailjs.send('service_e1t7yu2', 'template_zei6mmo', {
      customer_name,
      customer_lname,
      customer_email: user.email,
      customer_phone,
      payment_method,
      delivery_type,
      address_line_1,
      address_line_2,
      city,
      province,
      postal_code,
      total_amount:total
    }, 'LQRRTD335T1XqFDng')
      .then((response) => {
        console.log('Email sent:', response);

        dispatch(clearCart());
        setIsLoading(false)

        // Clear form data after successful submission
        dispatch(updateFormData({
          customer_name: '',
          customer_email: '',
          customer_phone: '',
          delivery_type: 'Pickup',
          address_line_1: '',
          address_line_2: '',
          city: '',
          province: '',
          postal_code: '',
          payment_method: 'Cash',
          total_amount: 0,
          submitted: true
        }));
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Email error:', error);
      });
  };
  const handleNext = () => {

    if (activeStep === steps.length - 1) {
      // send mail 
      sendMail();
       
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  if(isLoading) {
    return <FullPageLoader></FullPageLoader>

  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Checkout
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
           <OrderConfirmation /> 
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}
                <PrimaryButton buttonRounded={true} onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                </PrimaryButton>
              </Box>
            </React.Fragment>
          )}
        </Paper>
      </Container>
    </React.Fragment>
  );
}
