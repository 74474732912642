import Footer from 'Components/Footer'
import Navbar from 'Components/Navbar'
import AnimationRevealPage from 'helpers/AnimationRevealPage'
import React from 'react'
import ForgotPasswordForm from 'Components/forms/ForgotPasswordForm'
const ForgotPassword = () => {
  return (
    <AnimationRevealPage>
        <Navbar />
         <ForgotPasswordForm />
        <Footer />
    </AnimationRevealPage>
  )
}

export default ForgotPassword
