import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

const pickup = ['64 west lane', 'sandton city', 'sandton city', 'Guateng', '123489'];
const payments = [
  { name: 'Card type', detail: 'Visa' },
  { name: 'Card holder', detail: 'Mr John Smith' },
  { name: 'Card number', detail: 'xxxx-xxxx-xxxx-1234' },
  { name: 'Expiry date', detail: '04/2024' },
];

export default function Review() {
  const dispatch = useDispatch();
  const { cartItems, total, amount } = useSelector((store) => store.cart);
  const { user } = useSelector((store) => store.user);
  const {
    customer_name,
    customer_lname,
    payment_method,
    delivery_type,
    address_line_1,
    address_line_2,
    city,
    province,
    postal_code
  } = useSelector((store) => store.order);
  const delivery = [address_line_1, address_line_2, city, province, postal_code];

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        {cartItems.map((product) => (
          <ListItem key={product.name} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={product.title} secondary={product.title} />
            <Typography variant="body2">{product.price}</Typography>
          </ListItem>
        ))}
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            R &nbsp;{total}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Shipping
          </Typography>
          <Typography gutterBottom> {customer_name} {customer_lname}</Typography>
          {delivery_type === 'Pickup' ?  <Typography gutterBottom>{pickup.join(', ')}</Typography> :  <Typography gutterBottom>{delivery.join(', ')}</Typography>}
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Payment method
          </Typography>
          <Grid container>
            
              <React.Fragment >
                <Grid item xs={6}>
                  <Typography gutterBottom>Payment Method</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment_method}</Typography>
                </Grid>
              </React.Fragment>
 
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}