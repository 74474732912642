import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import tw from "twin.macro";
import styled from "styled-components";

import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons.js";
import EmailIllustrationSrc from "../../Assets/images/email-illustration.svg";
import { ToastContainer, toast } from 'react-toastify';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../../firebase/config.js';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
    tw`mt-16 md:w-7/12 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);



const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`h-full bg-center bg-no-repeat bg-contain rounded`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 bg-transparent border-t-0 border-r-0 border-l-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`
const notify = () => {

    toast.success('Message sent!', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        progress: undefined,
        draggable: true,
        theme: "light",
    })
};

const notifyError = () => {

    toast.error('SOmething went wrong, please try again!', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        progress: undefined,
        draggable: true,
        theme: "light",
    })
};
const SubmitMessage = tw.div`inline-block mt-8`
export default ({
    subheading = "Forgot Password?",
    heading = <>Let's  <span tw="text-primary-500">reset</span><wbr /> your password.</>,
    description = "Check your spam folder if you don't see it in your inbox.",
    submitButtonText = "Send",
    formAction = "#",
    formMethod = "get",
    textOnLeft = true,
}) => {
    // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
    const form = useRef();
    const [message, setMessage] = useState({
        name: "",
        message: "",
        email: "",
        number: "",
        sent: false,
        buttonText: "Send Message",
        emailError: false,
    });


    const sendEmail = (e) => {
        e.preventDefault();
       
        sendPasswordResetEmail(auth, form.current.email.value)
            .then(() => {
                // Password reset email sent!
                notify();
                form.current.reset();
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorMessage)
                
                notifyError();
                // ..
            });



    };
    return (
        <Container>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"

            />
            <TwoColumn>
                <ImageColumn>
                    <Image imageSrc={EmailIllustrationSrc} />
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        {subheading && <Subheading>{subheading}</Subheading>}
                        <Heading>{heading}</Heading>
                        {description && <Description>{description}</Description>}
                        <Form ref={form} onSubmit={sendEmail} >
                            <Input type="email" name="email" placeholder="Your Email Address" />
                            <SubmitButton type="submit">{message.buttonText}</SubmitButton>
                        </Form>

                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
};
