import React, { useState } from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "../Components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";

import illustration from "../Assets/images/signup-illustration.svg";
import logo from "../Assets/images/logo.svg";

import { ReactComponent as facebookIcon } from "../Assets/images/facebook-icon.svg";
import twitterIconImageSrc from "../Assets/images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import Navbar from "Components/Navbar.js";
import Footer from "Components/Footer.js";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../firebase/config.js";
import { addUser } from "../redux/userSlice.js";
import { useNavigate, useLocation } from "react-router-dom";
import { signInWithPopup, TwitterAuthProvider } from "firebase/auth";
import FullPageLoader from "Components/FullPageLoader.js";
import { ToastContainer, toast } from "react-toastify";

const Container = tw(
  ContainerBase
)`min-h-screen text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-6 flex flex-col items-center`;
const Heading = tw.h1`text-xl xl:text-2xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-3`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`flex items-center justify-center w-full max-w-xs py-3 mt-5 text-sm font-semibold text-gray-900 transition-all duration-300 bg-gray-100 border rounded-lg hocus:bg-gray-200 hocus:border-gray-400 focus:outline-none focus:shadow-outline first:mt-0`}
  .iconContainer {
    ${tw`p-2 bg-white rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const LinksContainer = tw.div`mt-5`;
const Input = tw.input`mt-6 first:mt-0 bg-transparent border-t-0 border-t-0 border-r-0 border-l-0 border-b-2 py-3 focus:outline-none focus:border-none  focus:border-transparent focus:ring-0 font-medium transition duration-300 hocus:border-primary-500`;
const SubmitButton = styled.button`
  ${tw`flex items-center justify-center w-full py-4 mt-5 font-semibold tracking-wide text-gray-100 transition-all duration-300 ease-in-out bg-orange-500 rounded-lg hover:bg-orange-100 focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`w-full max-w-lg m-12 bg-center bg-no-repeat bg-contain xl:m-16`}
`;

export default ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "",
  socialButtons = [
    {
      iconImageSrc: facebookIcon,
      text: "Sign Up With FaceBook",
      name: "facebook",
    },
    {
      iconImageSrc: twitterIconImageSrc,
      text: "Sign Up With Twitter",
      name: "twitter",
    },
  ],
  submitButtonText = "Sign Up",
  SubmitButtonIcon = SignUpIcon,
  tosUrl = "/termaandconditions",
  privacyPolicyUrl = "/privacy",
  signInUrl = "/login",
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const provider = new TwitterAuthProvider();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((store) => store.user);
  const [userData, setUserData] = useState({});

  const notify = () => {
    toast.error("Email already in use!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const twitterSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
        // You can use these server side with your app's credentials to access the Twitter API.
        const credential = TwitterAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const secret = credential.secret;

        // The signed-in user info.
        const user = result.user;
        setUserData({});
        dispatch(addUser(user));
        // navigate to previous page
        // Check if user was redirected from the cart
        if (location.state && location.state.from === "cart") {
          // Redirect user back to the cart
          navigate("/checkout");
        } else {
          // Redirect user to the homepage
          navigate("/");
        }
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = TwitterAuthProvider.credentialFromError(error);
        console.log(errorMessage);
        // ...
      });
  };

  const faceBookSignIn = () => {};

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!userData.email) {
      newErrors.email = "email is required";
    }

    if (!userData.password) {
      newErrors.password = "Password is required";
    }
    if (!userData.confirmPassword) {
      newErrors.confirmPassword = "confirm password is required";
    }
    // You can add more validation rules for other fields here
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setIsLoading(true);
    const userCreds = await createUserWithEmailAndPassword(
      auth,
      userData.email,
      userData.password
    )
      .then((userCredential) => {
        // Signed up
        const user = userCredential.user;
        setIsLoading(false);
        setUserData({});
        //dispatch(addUser(user));
        // navigate to previous page
      })
      .catch((error) => {
        newErrors.errorCode = error.code;
        newErrors.errorMessage = error.message;
        setIsLoading(false);
        notify();
        console.log(newErrors);
        return;
        // ..
      });

    await sendEmailVerification(auth.currentUser)
      .then(() => {
        // email verification modal
        navigate("/activation");
      })
      .catch((error) => {
        newErrors.errorCode = error.code;
        newErrors.errorMessage = error.message;
        setErrors((prev) => ({ ...prev, newErrors }));
        console.log(newErrors);
      });
  };
  const handleChange = ({ target: { name, value } }) => {
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  if (isLoading) {
    return <FullPageLoader></FullPageLoader>;
  }

  return (
    <AnimationRevealPage>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Navbar />
      <Container>
        <Content>
          <MainContainer>
            <MainContent>
              <FormContainer>
                <SocialButtonsContainer>
                  {socialButtons.map((socialButton, index) => (
                    <SocialButton
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();

                        socialButton.name == "twitter"
                          ? twitterSignIn()
                          : faceBookSignIn();
                      }}
                    >
                      <span className="iconContainer">
                        <img
                          src={socialButton.iconImageSrc}
                          className="icon"
                          alt=""
                        />
                      </span>
                      <span className="text">{socialButton.text}</span>
                    </SocialButton>
                  ))}
                </SocialButtonsContainer>
                <DividerTextContainer>
                  <DividerText>Or Sign up with your e-mail</DividerText>
                </DividerTextContainer>
                <Form onSubmit={handleSubmit}>
                  <Input
                    type="email"
                    onChange={handleChange}
                    name="email"
                    value={userData.email}
                    placeholder="Email"
                  />
                  <Input
                    type="password"
                    onChange={handleChange}
                    name="password"
                    value={userData.password}
                    placeholder="Password"
                  />
                  <Input
                    type="password"
                    onChange={handleChange}
                    value={userData.confirmPassword}
                    placeholder="Confirm Password"
                    name="confirmPassword"
                  />
                  <SubmitButton type="submit">
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                  <LinksContainer>
                    <p tw="mt-6 text-xs text-orange-600 text-center">
                      I agree to abide by Maveg's{" "}
                      <a
                        href={tosUrl}
                        tw="border-b border-gray-500 border-dotted"
                      >
                        Terms of Service
                      </a>{" "}
                      and its{" "}
                      <a
                        href={privacyPolicyUrl}
                        tw="border-b border-gray-500 border-dotted"
                      >
                        Privacy Policy
                      </a>
                    </p>

                    <p tw="mt-8 text-sm text-orange-600 text-center">
                      Already have an account?{" "}
                      <a
                        href={signInUrl}
                        tw="border-b border-gray-500 border-dotted"
                      >
                        Sign In
                      </a>
                    </p>
                  </LinksContainer>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
