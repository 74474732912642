import CartContainer from '../Components/CartContainer';
import { useDispatch, useSelector } from 'react-redux';
import { calculateTotals, getCartItems } from '../redux/cartSlice';
import { useEffect } from 'react';
import Modal from '../Components/Modal';
import Navbar from 'Components/Navbar';
import AnimationRevealPage from 'helpers/AnimationRevealPage';
import Footer from 'Components/Footer';
const Cart = () =>  {
  const { cartItems, isLoading } = useSelector((store) => store.cart);
  const { isOpen } = useSelector((store) => store.modal);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(calculateTotals());
  }, [cartItems]);

  // useEffect(() => {
  //   dispatch(getCartItems('random'));
  // }, []);

  // if (isLoading) {
  //   return (
  //     <div className='loading'>
  //       <h1>Loading...</h1>
  //     </div>
  //   );
  // }

  return (
    <AnimationRevealPage>
      {isOpen && <Modal />}
      <Navbar />
      <CartContainer />
      <Footer />
    </AnimationRevealPage>
  );
}
export default Cart;
