import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import tw from "twin.macro";
// import styled from "styled-components"; //eslint-disable-line
// import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../Components/headers/light.js";
import Footer from "../Components/Footer.js";
import MainFeature1 from "../Components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "../Components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "../Components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "../Assets/images/support-icon.svg";
import ShieldIconImage from "../Assets/images/shield-icon.svg";
import CustomerLoveIconImage from "../Assets/images/simple-icon.svg";
import Navbar from "Components/Navbar.js";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage className="App">
      <Navbar />
      <MainFeature1
        subheading={<Subheading>About Maveg</Subheading>}
        heading="Harvested with care, delivered with love.."
        buttonRounded={true}
        primaryButtonText="Shop now"
        imageSrc="https://res.cloudinary.com/deuo4szkv/image/upload/v1708450906/farm_exogpg.jpg"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="Bringing you the finest fruits and veggies, handpicked for perfection."
        description = "The company aims to positioning itself as one of the biggest role players in agro-processing space in South Africa by investing in developing its own processing plants for the production of its pre-packed fruits and vegetables range packages that it is currently processing from partnered processors. "
        buttonRounded={true}
        primaryButtonText="Contact Us"
        imageSrc="https://res.cloudinary.com/deuo4szkv/image/upload/v1708176607/cld-sample-4.jpg"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="At Maveg, we hold ourselves to the highest standards, guided by our core values. These values are the foundation of everything we do, from sourcing the finest produce to providing exceptional customer service. Discover what sets us apart and drives our commitment to excellence:"
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Quality Assurance",
            description: "We are dedicated to offering only the freshest, highest-quality fruits and vegetables, sourced from trusted farmers and suppliers who share our commitment to quality."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Community Engagement",
            description: "We believe in giving back to the communities we serve, supporting local growers and initiatives that promote access to fresh, nutritious food for all."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Our customers are at the heart of everything we do. We are committed to providing an exceptional shopping experience, from user-friendly online ordering to prompt delivery and attentive customer support."
          },
        ]}
        linkText=""
      />
      <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
