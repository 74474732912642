import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { Container, ContentWithPaddingXl } from "../misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings";
import { SectionDescription } from "../misc/Typography";
import  TwitterIcon  from "../../Assets/images/twitter-icon.png";
import LinkedinIcon from "../../Assets/images/linkedin-icon.svg";
import GithubIcon from "../../Assets/images/github-icon.svg";

const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`
const CardImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`w-64 h-64 bg-center bg-contain rounded`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`text-xs font-bold tracking-widest uppercase text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`

const CardLinks = styled.div`
  ${tw`flex mt-6`}
  .link {
    ${tw`mr-8 text-gray-400 transition duration-300 last:mr-0 hocus:text-primary-500`}
    .icon {
      ${tw`w-6 h-6 fill-current`}
    }
  }
`

export default ({
  heading = "Meet These Fine Folks.",
  subheading = "Our Team",
  description = "At Maveg, we're proud to have a dedicated team of passionate individuals who are committed to bringing you the best possible shopping experience. Get to know the faces behind the scenes who work tirelessly to ensure that every order is filled with care and attention to detail.",
  cards = [
    {
      imageSrc: "https://res.cloudinary.com/deuo4szkv/image/upload/v1708451920/mokone_jl86iw.jpg",
      position: "Founder",
      name: "Tshoarelo Mahloko",
     
    },
 
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <CardContent>
                <span className="position">{card.position}</span>
                <span className="name">{card.name}</span>
          
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};
