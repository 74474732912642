import Footer from 'Components/Footer'
import Navbar from 'Components/Navbar'
import SingleCol from 'Components/SingleCol'
import AnimationRevealPage from 'helpers/AnimationRevealPage'
import React from 'react'

const FAQs = () => {
  return (
    <AnimationRevealPage>
      <Navbar />
      <SingleCol />
      <Footer />
    </AnimationRevealPage>
  )
}

export default FAQs
