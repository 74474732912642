import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { updateFormData } from '../../redux/orderSlice';
import { useDispatch, useSelector } from 'react-redux';
export default function PaymentForm() {
  const [formData, setFormData] = React.useState({
    payment_method: 'Cash',
  });
  const {
    payment_method,
  } = useSelector((store) => store.order);
  const dispatch = useDispatch();

  const handleChange = ({ target: { name, value } }) => {
   
    const field = { [name]: value }

    dispatch(updateFormData(field))
  }
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment method
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="payment_method"
            value={payment_method}
            onChange={handleChange}
          >
            <FormControlLabel value="Cash" control={<Radio />} label="Cash On Delivery" />
            <FormControlLabel value="EFT" control={<Radio />} label="EFT" />
            <FormControlLabel value="Card" control={<Radio />} label="Card On Delivery" />
          </RadioGroup>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardName"
            label="Name on card"
            fullWidth
            autoComplete="cc-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardNumber"
            label="Card number"
            fullWidth
            autoComplete="cc-number"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="expDate"
            label="Expiry date"
            fullWidth
            autoComplete="cc-exp"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cvv"
            label="CVV"
            helperText="Last three digits on signature strip"
            fullWidth
            autoComplete="cc-csc"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}