import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData } from '../../redux/orderSlice';
import { MotionValue } from 'framer-motion';
export default function AddressForm() {
    const dispatch = useDispatch();
    const { cartItems, total, amount } = useSelector((store) => store.cart);
    const {
        delivery_type,
    } = useSelector((store) => store.order);
    const [errors, setErrors] = React.useState({});




    const handleChange = ({ target: { name, value } }) => {

        const field = { [name]: value }

        dispatch(updateFormData(field))

        document.getElementById(name).value = value;

    }
    return (
        <React.Fragment>


            <Typography variant="h6" gutterBottom>
                Shipping address
            </Typography>
            <Grid container spacing={4}>

                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="customer_name"
                        name="customer_name"
                        label="First name"
                        onBlur={handleChange}
                        fullWidth
                        autoComplete="given-name"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="customer_lname"
                        name="customer_lname"
                        label="Last name"
                        onBlur={handleChange}
                        fullWidth
                        autoComplete="family-name"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="customer_phone"
                        name="customer_phone"
                        label="Phone"
                        type="number"
                        onBlur={handleChange}
                        fullWidth
                        autoComplete="family-name"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">Delivery Option:</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="delivery_type"
                        value={delivery_type}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="pickup" control={<Radio />} label="Pick Up" />
                        <FormControlLabel value="delivery" control={<Radio />} label="Delivery" />

                    </RadioGroup>
                </Grid>
                {delivery_type === 'delivery' ? <>

                    <Grid item xs={12}>
                        <TextField
                            required
                            id="address_line_1"
                            name="address_line_1"
                            onBlur={handleChange}
                            label="Address line 1"
                            fullWidth
                            autoComplete="shipping address-line1"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="address_line_2"
                            name="address_line_2"
                            onBlur={handleChange}
                            label="Address line 2"
                            fullWidth
                            autoComplete="shipping address-line2"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="city"
                            name="city"
                            onBlur={handleChange}
                            label="City"
                            fullWidth
                            autoComplete="shipping address-level2"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="province"
                            name="province"
                            label="Province"
                            onBlur={handleChange}
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="postal_code"
                            name="postal_code"
                            type="number"
                            onBlur={handleChange}
                            label="Zip / Postal code"
                            fullWidth
                            autoComplete="shipping postal-code"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
                            label="Use this address for payment details"
                        />
                    </Grid>
                </> : null}

            </Grid>
        </React.Fragment>
    );
}